
.wap-only{
    display: none;
}
.hamburger {
    display: none;
}
@media screen and (max-width: 991px) {
    .user-info{
        color:$white
    } 
    .dashboard-bg {
        padding: 15px;
        h1 {
            font-size: 28px !important;
            line-height: 40px !important;
        }
    } 
    .web-only{
        display: none;
    }
    .wap-only{
        display: block;
    }
    .icon-bar{
        height: 25px;
    }

    header{ 
        position: sticky; 
        top: 0px; 
        .ic-menu{
            color: $white;
            font-size: 20px;
        }
        .header-ul{
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            .logo-mob{
                display: flex;
                align-items: center;
            }
        }
    }

    .wap-header {
        height: 48px;
    }

    .login-outer{
        padding: 0px 0px 20px;
        border-radius: 0px !important;
        flex-wrap: wrap;
        height: 100vh;
    }
    
    .login-left-panel{
        width: 100% !important;
        height: auto !important;
        margin-bottom: 30px;
        .login-bg {
            padding-top: 0px !important;
            border-radius: 0px !important;
        }
        .login-txt {
            width: 100% !important;
            padding-top: 0px !important;
        } 
        .login-bg-img { 
            display: flex;
            bottom: 0px !important; 
        }
    }
    
    .login-right-panel{ 
        width: 100%;
        padding: 0px 15px !important;

        .form-input{
            width: 100% !important;
        }

        .cap-img{
            top: -11px !important;
        }

    }
    .hamburger { 
        display: block;
        background: #fff;
        box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
        -ms-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
        width: 260px;
        position: fixed;
        top: 0;
        bottom: 0;
        max-height: none;
        overflow-y: scroll;
        will-change: transform;
        height: 100%;
        z-index: 9999;
        transform: translateX(-108%);
        -webkit-transform: translateX(-108%);
        &.active {
            transform: translateX(-15px);
            -webkit-transform: translateX(-15px);
        }
        ul {
            padding: 35px 0px 20px;
            li {
                border-bottom: 1px solid rgba($heading-color, 0.3);
                a {
                    color: $heading-color;
                    padding: 10px 20px;
                    display: inline-block;
                    width: 100%;
                }
                &.optionsubmenu {
                    a {
                        &::before {
                            content: "\e917";
                            position: absolute;
                            right: 10px;
                            font-family: "dc" !important;
                            color: $heading-color;
                        }
                    }
                    ul {
                        height: 0;
                        transition: height 1s ease-out;
                        -webkit-transform: scaleY(0);
                        -o-transform: scaleY(0);
                        -ms-transform: scaleY(0);
                        transform: scaleY(0);
                        display: none;
                        padding-top: 0px;
                        padding-bottom: 0px;
                        background-color: rgba(217, 217, 217, 0.20);
                        li {
                            a {
                                padding-left: 40px;
                                &::before {
                                    display: none;
                                }
                            }
                            &:last-child {
                                border-bottom: 0px;
                            }
                        }
                    }
                    &.active {
                        ul {
                            display: block;
                            -webkit-transform: scaleY(1);
                            -o-transform: scaleY(1);
                            -ms-transform: scaleY(1);
                            transform: scaleY(1);
                            -webkit-transform-origin: top;
                            -o-transform-origin: top;
                            -ms-transform-origin: top;
                            transform-origin: top;
                            -webkit-transition: -webkit-transform 0.4s ease-out;
                            -o-transition: -o-transform 0.4s ease;
                            -ms-transition: -ms-transform 0.4s ease;
                            transition: transform 0.4s ease;
                            box-sizing: border-box;
                            height: auto;
                        }
                        a { 
                            &::before {
                                content: "\e91a";
                            }
                        }
                    }
                }

            }

        }
        .ic-clearclose {
            position: absolute;
            right: 8px;
            top: 8px;
            cursor: pointer;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .1);
            border-radius: 100%;
            background: #fff;
            height: 24px;
            width: 24px;
            font-size: 10px;
            z-index: 2;
            justify-content: center;
            align-items: center;
        }
    }

    .menu-overlay {
        display: none;
        &.active {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 99;
            background: rgba(0, 0, 0, 0.3);
            display: block;
        }
    }
    .animate {
        -webkit-transition: transform 0.2s ease-in-out;
        -moz-transition: transform 0.2s ease-in-out;
        -o-transition: transform 0.2s ease-in-out;
        transition: transform 0.2s ease-in-out;
    }
    
   
    
}
